<template>
    <div
        class="chat"
        @dragover.stop.prevent
        @drop.stop.prevent="processDropFile($event)"
    >

        <div class="chat__bg"></div>

        <div
            v-show="showTopLoading"
            class="chat__top-loading"
        >
            <v-progress-circular
                indeterminate
                color="#505050"
                size="20"
                width="2"
            ></v-progress-circular>
        </div>

        <div
            v-show="!showTopLoading"
            class="chat__date chat__date--top"
            ref="topDate"
        ></div>

        <div
            class="chat__scroll-button"
            ref="scrollButton"
            @click="scrollChat(false)"
        >
            <svg ref="scrollIcon" style="fill: #51585c; overflow: hidden;" viewBox="0 0 1024 1024"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M707.84 238.506667 768 298.666667 512 554.666667 256 298.666667 316.16 238.506667 512 433.92 707.84 238.506667M707.84 494.506667 768 554.666667 512 810.666667 256 554.666667 316.16 494.506667 512 689.92 707.84 494.506667Z"/>
            </svg>
        </div>

        <div
            id="chat-messages"
            class="chat__messages"
            ref="messages"
        >
            <template
                v-for="(message, key) in messages"
            >
                <div
                    v-if="!messages[key - 1] || message.date != messages[key - 1].date"
                    class="chat__date"
                    :class="{'hidden': !messages[key - 1]}"
                    v-show="message.show"
                    ref="date"
                >
                    {{ message.date }}
                </div>
                <message
                    :key="message.id"
                    class="chat__message"
                    :message="message"
                    :messagesRef="$refs.messages"
                    v-show="message.show"
                    @beforeLoaded="messageBeforeLoaded"
                    @contextCalled="contextCalled"
                    @reply="replyAdd"
                ></message>
            </template>
        </div>

        <div class="chat__attachments" v-if="attachments.length">
            <attachment
                v-for="(attachment, key) in attachments"
                :key="key"
                :attachment="attachment"
                @remove="removeFile(key)"
                class="chat__attachment"
            ></attachment>
        </div>

        <div class="chat__control">
            <div ref="input" class="chat__input">
                <div class="chat__input-text">
                    <div v-if="reply" class="chat__reply">
                        <img class="chat__reply-arrow" src="/img/chat/reply-arrow-right.svg" alt="">
                        <div class="chat__reply-text" v-html="reply.html"></div>
                        <v-btn
                            class="chat__reply-remove"
                            color="error"
                            small
                            icon
                            @click="replyRemove"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    <textarea
                        @input="input"
                        :value="inputText"
                        ref="textarea"
                        type="text"
                        placeholder="Сообщение"
                        rows="1"
                        class="chat__textarea"
                        @keydown.enter="send"
                    ></textarea>
                </div>
                <clip
                    @filesChanged="changeFiles"
                ></clip>
            </div>

            <button
                @click="send"
                type="button"
                class="chat__send"
                :class="{'chat__send--disabled': role == 'ghost'}"
                :disabled="role == 'ghost'"
            >
                <svg
                    v-if="!loading"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="chat__send-icon"
                    color="#ffffff">
                    <path
                        fill="currentColor"
                        d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                    ></path>
                </svg>
                <v-progress-circular
                    v-if="loading"
                    :size="24"
                    :width="2"
                    indeterminate
                    color="white"
                    class="chat__send-loading"
                ></v-progress-circular>
            </button>
        </div>

        <v-dialog
            v-model="messageContext"
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Use Google's location service?
                </v-card-title>

                <v-card-text>
                    Let Google help apps determine location. This means sending anonymous location data to Google, even
                    when
                    no apps are running.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="messageContext = false"
                    >
                        Disagree
                    </v-btn>

                    <v-btn
                        color="green darken-1"
                        text
                        @click="messageContext = false"
                    >
                        Agree
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="subscribeDialog"
            max-width="280"
        >
            <v-card>

                <v-card-title style="word-break:normal;">Разрешить отправлять уведомления?</v-card-title>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="subscribeDialog = false"
                        small
                    >
                        Нет
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="success"
                        @click="subscribe"
                        small
                    >
                        Да
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import message from '@/components/chat/Message'
import attachment from '@/components/chat/Attachment'
import clip from '@/components/chat/Clip'
import firebase from '@/firebase'
import {getMessaging, getToken, isSupported, onMessage} from 'firebase/messaging'

export default {

    components: {
        message,
        attachment,
        clip
    },


    data() {
        return {
            attachments: [],
            loading: false,
            gettingChatTimeout: null,
            dateTexts: {},
            dateCoordinates: [],
            contextMessage: null,
            messageContext: false,
            reply: null,
            subscribeDialog: false,
            scrollingChat: false,
            messagesHeight: 0,
            messagesScroll: 0,
            gettingPreviousMessages: false,
            initialScrolling: false,
            chatScrolled: true,
            messageIdsToLoad: [],
            loadingMessages: false,
            counter: 0,
            showTopLoading: false,
            allPreviousMessagesLoaded: false,
            newReadMessages: []
        }
    },


    created() {

        this.initNotifications()

        return this.$store.dispatch('updateChat')
            .catch(e => {
                console.error(e)
            })
            .then(() => {
                return this.getChat()
            })
    },


    mounted() {

        this.scrollChat(true)

        this.$refs.messages.addEventListener('scroll', () => {
            this.scroll()
        })

        // document.addEventListener('contextmenu', event => {
        //     event.stopPropagation()
        //     event.preventDefault()
        // })
    },


    watch: {
        clientId() {
            this.scrollChat(true)
            this.allPreviousMessagesLoaded = false
            return this.$store.dispatch('updateChat')
        }
    },


    computed: {

        ...mapState({
            messages: state => state.chat.messages,
            readMessages: state => state.main.readComments,
            gettingChatCycle: state => state.chat.gettingChatCycle,
            inputText: state => state.chat.inputText,
            role: state => state.common.user.role,
            clientId: state => state.main.clientId
        }),


        lastMessageId() {
            let lastMessageId = null
            let messages = this.messages.slice()

            messages.sort((a, b) => {
                if (a.id > b.id) return 1
                if (a.id == b.id) return 0
                if (a.id < b.id) return -1
            })

            for (let message of messages)
                if ('task_from_id' in message) {
                    lastMessageId = message.id
                    break
                }

            return lastMessageId
        }

    },


    methods: {

        ...mapMutations({
            changeGettingChatCycle: 'gettingChatCycle',
            changeInputText: 'inputText',
            updateReadComments: 'readComments',
            updateUnreadMessagesCount: 'unreadMessagesCount'
        }),


        getPreviousMessages() {
            if (this.gettingPreviousMessages) return false
            this.gettingPreviousMessages = true
            return this.$store.dispatch('request', {
                module: 'Chat',
                controller: 'Chat',
                action: 'getPreviousMessages'
            }).then(result => {
                this.allPreviousMessagesLoaded = !result
                if (!result) this.showTopLoading = false
            })
        },


        offerToSubscribe() {
            isSupported()
                .then(res => {
                    if (!res) return false

                    if (Notification.permission == 'granted')
                        return this.initNotifications()

                    if (Notification.permission == 'default')
                        return this.subscribeDialog = true
                })
        },


        subscribe() {
            this.subscribeDialog = false
            return this.initNotifications()
        },


        initNotifications() {
            if (navigator.serviceWorker === undefined) return

            const messaging = getMessaging(firebase)

            onMessage(messaging, payload => null)

            return getToken(messaging,
                {
                    vapidKey: "BE2ChW5ZpvylBN1JmJK-ODijh0ajiwerQLXMz9CU8_qPFoaFERF1mUKFCpjgikErSdivbj4NeLyaUUG_eFhdYz4"
                })
                .then(currentToken => {
                    if (currentToken)
                        return this.$store.dispatch('savePushToken', currentToken)
                    else
                        return this.$store.dispatch('savePushToken', null)
                })
                .catch(() => {
                    return this.$store.dispatch('savePushToken', null)
                })
        },


        replyAdd(message) {
            this.reply = message
            this.refreshInputHeight()
            this.$refs.textarea.focus()
        },


        replyRemove() {
            this.reply = null
            this.refreshInputHeight()
        },


        contextCalled(message) {
            // console.info(message)
            // this.contextMessage = message
            // this.messageContext = true
        },


        scroll() {

            let scroll = this.$refs.messages.scrollTop
            let maxScroll = this.$refs.messages.scrollHeight - this.$refs.messages.clientHeight

            if (!this.initialScrolling && this.messageIdsToLoad.length == 0 && scroll >= maxScroll - 100)
                this.initialScrolling = true

            if (!this.allPreviousMessagesLoaded && this.initialScrolling && scroll < 1500)
                this.getPreviousMessages()

            this.showTopLoading = scroll == 0 && !this.allPreviousMessagesLoaded

            let coordinate = this.dateCoordinates.find((item, key, items) => {
                if (scroll >= item && (!items[key + 1] || scroll < items[key + 1]))
                    return true
            })

            if (coordinate !== undefined && this.$refs.topDate.textContent != this.dateTexts[coordinate])
                this.$refs.topDate.textContent = this.dateTexts[coordinate]

            if (maxScroll - scroll > 200) {
                this.$refs.topDate.style.top = '3px'
            }

            if (maxScroll - scroll < 200) {
                this.$refs.topDate.style.top = '-30px'
            }

            if (maxScroll - scroll > 300) {
                this.$refs.scrollButton.style.width = '40px'
                this.$refs.scrollButton.style.height = '40px'
                this.$refs.scrollButton.style.bottom = '84px'
                this.$refs.scrollButton.style.right = '13px'

                this.$refs.scrollIcon.style.width = '20px'
                this.$refs.scrollIcon.style.height = '20px'
            }

            if (maxScroll - scroll < 300) {
                this.$refs.scrollButton.style.width = '0'
                this.$refs.scrollButton.style.height = '0'
                this.$refs.scrollButton.style.bottom = '100px'
                this.$refs.scrollButton.style.right = '31px'

                this.$refs.scrollIcon.style.width = '0'
                this.$refs.scrollIcon.style.height = '0'
            }
        },


        loadMessages(start = false) {

            if (start && this.loadingMessages) return false

            this.loadingMessages = true

            if (start) {
                this.chatScrolled = this.$refs.messages.scrollTop >= this.$refs.messages.scrollHeight - this.$refs.messages.clientHeight - 100
                this.messagesHeight = this.$refs.messages.scrollHeight
                this.messagesScroll = this.$refs.messages.scrollTop
            }

            let id = this.messageIdsToLoad.shift()

            let message = this.messages.find(m => m.id == id)

            if (message) {
                message.show = true

                let dateTexts = {}
                this.$refs.date.forEach(date => {
                    dateTexts[date.getBoundingClientRect().top + this.$refs.messages.scrollTop - 53] = date.textContent.trim()
                })
                this.dateTexts = dateTexts

                this.dateCoordinates = this.$refs.date.map(date => date.getBoundingClientRect().top + this.$refs.messages.scrollTop - 53)
                this.dateCoordinates.sort((a, b) => a > b ? 1 : -1)

                if (message.own == '0' && !this.readMessages.includes(message.id))
                    this.newReadMessages.push(message.id)
            }

            setTimeout(() => {

                if (this.messageIdsToLoad.length) {
                    this.loadMessages()
                    return true
                }

                if (this.chatScrolled) this.scrollChat(true)

                this.loadingMessages = false

                if (this.gettingPreviousMessages) {
                    this.scrollChat(true, this.$refs.messages.scrollHeight - this.messagesHeight + this.messagesScroll)
                        .finally(() => {
                            this.gettingPreviousMessages = false
                        })
                }

                if (this.newReadMessages.length && this.role != 'ghost') {
                    let newReadMessages = this.newReadMessages.slice()
                    this.newReadMessages = []
                    this.$store.dispatch('addReadComments', newReadMessages)
                        .then(() => {
                            let readComments = this.readMessages.concat(newReadMessages)
                            this.updateReadComments(readComments)
                            this.updateUnreadMessagesCount(0)
                        })
                }
            })

        },


        messageBeforeLoaded(id) {
            this.messageIdsToLoad.push(id)
            setTimeout(() => {
                this.loadMessages(true)
            })
        },


        getChat() {
            if (this.$route.name != 'wiChat') return false
            if (this.gettingChatCycle) return false
            this.changeGettingChatCycle(true)
            return this.$store.dispatch('getChat', this.lastMessageId)
                .then(result => {
                    if (result === true && this.messageIdsToLoad.length == 0)
                        this.gettingPreviousMessages = false
                })
                .catch(() => {
                })
                .finally(() => {
                    setTimeout(() => {
                        this.changeGettingChatCycle(false)
                        this.getChat()
                    }, 3000)
                })
        },


        scrollChat(instantly = false, coordinate = null) {
            return new Promise(resolve => {
                if (!this.$refs.messages) {
                    resolve()
                    return false
                }
                if (this.scrollingChat) {
                    resolve()
                    return false
                }

                if (coordinate === null) coordinate = this.$refs.messages.scrollHeight

                if (this.$refs.messages.scrollTop == coordinate) {
                    resolve()
                    return true
                }

                this.scrollingChat = true

                if (instantly) {
                    this.$refs.messages.scrollTop = coordinate
                    resolve()
                    return true
                }

                setTimeout(() => {
                    let step = Math.round((coordinate - this.$refs.messages.scrollTop - this.$refs.messages.clientHeight) / 30)
                    this.smoothScroll(step, coordinate)
                        .finally(() => {
                            resolve()
                        })
                })
            }).finally(() => {
                this.scrollingChat = false
            })
        },


        smoothScroll(step, coordinate) {
            return new Promise(resolve => {
                let oldScrollTop = this.$refs.messages.scrollTop
                this.$refs.messages.scrollTop += step
                if (
                    oldScrollTop == this.$refs.messages.scrollTop ||
                    coordinate - this.$refs.messages.scrollTop == 0 ||
                    Math.abs(coordinate - this.$refs.messages.scrollTop) < Math.abs(step)
                )
                    resolve(false)
                else
                    setTimeout(() => resolve(true), 1)
            }).then(res => {
                if (res) return this.smoothScroll(step, coordinate)
            })
        },


        input() {
            this.changeInputText(this.$refs.textarea.value)
            this.refreshInputHeight()
        },


        refreshInputHeight() {
            if (this.$refs.textarea.scrollHeight == this.$refs.textarea.clientHeight) {
                this.$refs.textarea.style.height = ''
                this.$refs.input.style.height = ''
            }
            if (this.$refs.textarea.scrollHeight > this.$refs.textarea.clientHeight && this.$refs.textarea.scrollHeight < 146) {
                this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px'
                this.$refs.input.style.height = this.reply ? this.$refs.textarea.scrollHeight + 46 + 'px' : this.$refs.textarea.scrollHeight + 'px'
            }
        },


        processDropFile(event) {
            let newFiles = []
            for (let file of event.dataTransfer.files) {
                if (file.type.startsWith('image/')) {
                    let reader = new FileReader()
                    reader.onload = e => {
                        newFiles.push({
                            name: file.name,
                            src: e.target.result,
                            file: file
                        })
                        if (event.dataTransfer.files.length === newFiles.length)
                            this.changeFiles(newFiles);
                    }
                    reader.readAsDataURL(file)
                } else {
                    newFiles.push({
                        name: file.name,
                        file: file
                    })
                    if (event.dataTransfer.files.length === newFiles.length)
                        this.changeFiles(newFiles);
                }
            }
        },


        changeFiles(newFiles) {
            this.attachments = this.attachments.concat(newFiles)
        },


        removeFile(key) {
            this.attachments.splice(key, 1)
        },


        send(event) {

            if (event.shiftKey) return

            if (event.code === '') return

            event.preventDefault()

            if (this.role == 'ghost') return

            if (this.loading) return false

            if (!this.inputText.trim() && !this.attachments.length) return false

            let files = this.attachments.map(item => item.file)
            let text = this.inputText.trim()

            this.changeInputText('')
            this.attachments = []

            this.$refs.textarea.style.height = ''
            this.$refs.input.style.height = ''

            let formData = new FormData()
            formData.append('module', 'Chat')
            formData.append('controller', 'Chat')
            formData.append('action', 'addMessage')
            formData.append('text', text)

            if (this.reply)
                formData.append('reply', this.reply.id)

            files.forEach((file, key) => {
                formData.append('file' + key, file)
            })

            this.loading = true
            return this.$store.dispatch('request', formData)
                .finally(() => {
                    this.reply = null
                })
                .then(() => {
                    return this.$store.dispatch('getChat', this.lastMessageId)
                })
                .catch(() => {
                })
                .then(() => {
                    return this.$store.dispatch('updateChat', false)
                }).catch(() => {
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }

}
</script>

<style lang="scss">

.chat {
    position: relative;
    height: 100%;
    background-color: #e5ddd5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .hidden {
        visibility: hidden;
    }

    &__scroll-button {
        position: absolute;
        z-index: 2;
        bottom: 92px;
        right: 30px;
        width: 0;
        height: 0;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0 1px 1px 0 rgba(11, 20, 26, .06), 0 2px 5px 0 rgba(11, 20, 26, .2);
        transition: all 0.3s;

        svg {
            width: 20px;
            height: 20px;
            transition: all 0.3s;
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/img/chat/bg-dark.png');
        background-repeat: repeat;
    }

    &__date {
        position: relative;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 1px .5px rgba(11, 20, 26, 0.13);
        padding: 5px 12px 6px;
        border-radius: 7.5px;
        z-index: 2;
        color: #505050;
        font-size: 12px;
        font-weight: 500;
        margin-top: 5px;
        margin-bottom: 5px;
        align-self: center;

        &--top {
            position: absolute;
            top: -30px;
            margin: 0;
            transition: all 0.3s
        }
    }

    &__top-loading {
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 1px .5px rgba(11, 20, 26, 0.13);
        padding: 2px 40px 3px;
        border-radius: 7.5px;
        z-index: 2;
        position: absolute;
        top: 3px;
        transition: all 0.3s;
        align-self: center;
    }

    &__messages {
        position: relative;
        z-index: 1;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        flex: 0 1 calc(100% - 62px);
        margin-bottom: 8px;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__attachments {
        z-index: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        overflow-y: scroll;
        max-height: calc(66% - 34px);
        flex: 1 0 auto;
        margin-bottom: 8px;
        background-color: rgba(0, 0, 0, 0.2);
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__control {
        display: flex;
        position: relative;
        width: 100%;
        margin-bottom: 8px;
    }

    &__input {
        margin: 0 8px;
        width: calc(100% - 66px);
        background-color: #fff;
        border-radius: 23px;
        padding: 0 18px;
        outline: none;
        display: flex;
        align-items: center;
    }

    &__input-text {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
    }

    &__reply {
        height: 46px;
        padding: 11px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.45);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            display: block;
            left: -18px;
            bottom: -1px;
            width: 18px;
            height: 1px;
            background-color: rgba(0, 0, 0, 0.45);
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            right: -42px;
            bottom: -1px;
            width: 42px;
            height: 1px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.45);
        }
    }

    &__reply-arrow {
        position: absolute;
        width: 18px;
        height: auto;
    }

    &__reply-text {
        overflow: hidden;
        height: 100%;
        position: relative;
        padding-left: 32px;
    }

    &__reply-remove {
        position: absolute;
        right: -24px;
        bottom: 7px;
    }

    &__textarea {
        resize: none;
        outline: none;
        width: 100%;
        height: 46px;
        padding: 11px 0;
    }

    &__file-label {
        align-self: flex-end;
        margin-bottom: 11px;
    }

    &__send {
        position: relative;
        margin-right: 8px;
        display: block;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background-color: #02a884;
        align-self: flex-end;

        &--disabled {
            background-color: #607d8b;
        }
    }

    &__send-loading {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
    }

    &__send-icon {
        position: absolute;
        top: 12px;
        left: 14px;
        width: 21px;
        height: 21px;
    }
}

</style>