<template>
    <div
        class="chat-message"
        :class="{'chat-message--own': message.own == '1'}"
        ref="message"
        @pointerdown="clickMessage"
    >
        <div
            v-if="message.files"
            class="chat-message__files"
        >
            <div
                v-for="file in message.files"
                class="chat-message__file"
            >
                <div
                    v-if="file.thumbnail"
                    class="chat-message__file-thumbnail"
                >
                    <a :href="file.link" download>
                        <img :src="file.thumbnail" :alt="file.name">
                    </a>
                </div>

                <div
                    v-else
                    class="chat-message__file-icon"
                >
                    <a :href="file.link" download>
                        <v-icon size="135" class="mx-auto">
                            mdi-file-document-outline
                        </v-icon>
                        <div class="chat-message__file-name">
                            {{ file.name }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div
            v-if="message.html"
            v-html="message.html"
            class="chat-message__text"
        ></div>
        <div class="chat-message__time-status">
            <div class="chat-message__time">
                {{ message.time }}
            </div>
            <div class="chat-message__status">
            </div>
        </div>

        <v-menu
            left
            :nudge-left="35"
            :nudge-top="15"
            max-width="none"
            z-index="999"
            v-model="plusMenu"
            v-if="message.own == '1' && role != 'minor' && projectForTasksId"
        >
            <template v-slot:activator="{ on, attrs }">
                <div
                    class="chat-message__plus"
                    v-bind="attrs"
                    v-on="on"
                >
                    <img src="/img/chat/plus.svg" alt="">
                </div>
            </template>
            <v-list
                nav
                dense
                class="pa-0"
            >
                <v-list-item
                    class="chat-message__plus-item pl-3 pr-4 py-1"
                    @click.stop="createTaskDialog = true"
                >
                    <v-list-item-icon class="mr-3">
                        <v-icon>mdi-rocket-launch-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Отдать в работу</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <div
            v-if="message.own == '0'"
            class="chat-message__reply"
            title="Ответить"
            @click="reply"
        >
            <img src="/img/chat/reply-arrow-right.svg" alt="">
        </div>

        <v-dialog
            v-model="createTaskDialog"
            max-width="350"
        >
            <v-card>
                <v-card-title
                    class="subtitle-1"
                    style="word-break:break-word;line-height:1.6rem;"
                >
                    Создать задачу по комментарию?
                </v-card-title>
                <v-card-actions>
                    <v-btn
                        color="#02a884"
                        dark
                        small
                        :loading="createTaskLoading"
                        @click="createTask"
                    >
                        Создать
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#f8405d"
                        dark
                        small
                        outlined
                        @click="createTaskDialog = false"
                    >
                        Отмена
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import {mapState, mapActions} from "vuex";

export default {

    props: [
        'message',
        'messagesRef'
    ],


    data() {
        return {
            imagesLength: -1,
            unloadedImagesLength: -1,
            createTaskDialog: false,
            createTaskLoading: false,
            plusMenu: false
        }
    },


    computed: {
        ...mapState({
            readComments: state => state.main.readComments,
            role: state => state.common.user.role,
            projectForTasksId: state => state.main.projectForTasksId
        })
    },


    mounted() {
        this.processImages()
        this.processLinks()
    },


    watch: {
        unloadedImagesLength() {
            if (this.imagesLength == 0 || this.unloadedImagesLength == 0)
                this.$emit('beforeLoaded', this.message.id)
        },

        plusMenu() {
            if (this.plusMenu)
                this.messagesRef.addEventListener('scroll', () => {
                    this.plusMenu = false;
                }, {once: true})
        }
    },


    methods: {

        ...mapActions([
            'request',
            'alert'
        ]),

        reply() {
            this.$emit('reply', this.message)
        },

        clickMessage(event) {
            if (event.pointerType == 'mouse' && event.button != '2') return
            this.$emit('contextCalled', this.$refs.message)
        },

        processImages() {
            let images = this.$refs.message.querySelectorAll('img')
            this.imagesLength = images.length
            this.unloadedImagesLength = images.length
            images.forEach(image => {
                image.addEventListener('load', () => {
                    this.unloadedImagesLength--
                })
            })
        },

        processLinks() {
            let links = this.$refs.message.querySelectorAll('a')
            links.forEach(link => {
                link.setAttribute('target', '_blank')
            })
        },

        createTask() {
            this.createTaskLoading = true;
            return this.request({
                'module': 'Chat',
                'controller': 'Message',
                'action': 'createTask',
                'messageId': this.message.id,
                'isLocal': !('task_from_id' in this.message)
            }).then(
                () => {
                    this.alert({
                        'text': 'Задача передана в техцентр',
                        'color': '#02a884'
                    });
                    this.$store.dispatch('updateChat', true);
                },
                () => {
                    this.alert({
                        'text': 'Не удалось поставить задачу',
                        'color': '#f8405d'
                    });
                }
            ).finally(() => {
                this.createTaskDialog = false;
                this.createTaskLoading = false;
            });
        }

    }

}
</script>


<style lang="scss">

.chat-message {
    position: relative;
    background-color: #ffffff;
    align-self: start;
    padding: 8px 14px 5px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 8px;
    margin-right: 50px;
    word-break: break-word;

    &--own {
        background-color: #e7ffdb;
        align-self: flex-end;
        margin-left: 50px;
        margin-right: 8px;
    }

    &__text {
        color: #303030;
        margin-bottom: 10px;
        margin-right: 6px;
    }

    &__space {
        display: inline-block;
        width: 75px;

        @media(max-width: 660px) {
            width: 55px;
        }
    }

    &__time {
        float: right;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
    }

    &__file {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        cursor: pointer;
    }

    &__file-thumbnail img {
        max-width: 240px;
    }

    &__file-name {
        text-align: center;
    }

    &__reply {
        position: absolute;
        width: 30px;
        height: 30px;
        bottom: 5px;
        right: -35px;
        border-radius: 50%;
        background-color: #fff;
        cursor: pointer;

        img {
            width: 17px;
            height: auto;
            position: absolute;
            left: 7px;
            top: 6px;
        }
    }

    &__plus {
        position: absolute;
        width: 30px;
        height: 30px;
        bottom: 5px;
        left: -35px;
        border-radius: 50%;
        background-color: #e7ffdb;
        cursor: pointer;

        img {
            width: 24px;
            height: auto;
            position: absolute;
            left: 3px;
            top: 3px;
        }
    }

    &__plus-item {
        background-color: #ffffff;
        cursor: pointer;

        &:hover {
            background-color: #e7ffdb;
        }
    }
}

</style>