import {initializeApp} from "firebase/app";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyAXnQJmulp6TmLUq97GNATZ26lZ75NvHMY",
    authDomain: "lk-client.firebaseapp.com",
    projectId: "lk-client",
    storageBucket: "lk-client.appspot.com",
    messagingSenderId: "365102217243",
    appId: "1:365102217243:web:4c4003581daa0cc03760be"
};

// Initialize Firebase
export default initializeApp(firebaseConfig);