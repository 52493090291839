<template>
    <div class="chat-attachment">
        <div
            v-if="attachment.src"
            class="chat-attachment__image"
        >
            <img :src="attachment.src" alt="">
        </div>

        <div v-else class="chat-attachment__file-icon">
            <v-icon
                size="140"
                class="mx-auto"
            >
                mdi-file-document-outline
            </v-icon>
        </div>

        <div class="chat-attachment__bottom">
            <div class="chat-attachment__name" style="word-break: break-word;">
                {{ attachment.name }}
            </div>
            <v-btn
                color="error"
                small
                icon
                @click="remove"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {

    props: ['attachment'],

    methods: {

        remove() {
            this.$emit('remove')
        }

    }

}
</script>

<style lang="scss">

.chat-attachment {
    background-color: #fff;
    margin: 8px;
    width: 200px;

    @media(max-width: 660px) {
        margin: 4px;
        width: 45%;
    }

    &__image {
        width: 100%;
        aspect-ratio: 1 / 0.8;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__file-icon {
        text-align: center;
        aspect-ratio: 1/0.8;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px;
    }

    &__name {
        font-size: 10px;
        padding-right: 4px;
    }
}

</style>